import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { InputGroup } from "react-bootstrap";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { red } from "@mui/material/colors";

const EditResourcePageComponent = ({ updateResourceApiRequest, fetchResource }) => {

  const [validated, setValidated] = useState(false);
  const [resource, setResource] = useState([]);
  const [updateResourceResponseState, setUpdateResourceResponseState] = useState({ message: "", error: "" });

  const { id } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");                      
  const [name, setName] = useState("");                      
  const [surname, setSurname] = useState("");                      
  const [telephone, setTelephone] = useState("");                      
  const [email, setEmail] = useState("");                      
  const [address, setAddress] = useState("");                      
  const [code, setCode] = useState("");                      
  const [color, setColor] = useState("");                      
  const [image, setImage] = useState("");                      

  useEffect(() => {
    fetchResource(id)
    .then(data => {
      console.log(`data =`, data);  
      setTitle(data?.title);
      setName(data?.name);
      setSurname(data?.surname);
      setTelephone(data?.telephone);
      setEmail(data?.email);
      setAddress(data?.address);
      setCode(data?.code);
      setColor(data?.color);
      setImage(data?.image);
      console.log(`name  =`, name);  
      console.log(`surname  =`, surname);  
    })
    .catch((er) => console.log(er.response.data.message ? er.response.data.message : er.response.data));         
},[id])

function salvaResourceModificata (eventKey) {
  eventKey.preventDefault();
  eventKey.stopPropagation();

  console.log(`title dig =`, title);  
  console.log(`name dig =`, name);  
  console.log(`surname dig =`, surname);  
  console.log(`telephone dig =`, telephone);  
  console.log(`email dig =`, email);  
  console.log(`address dig =`, address);  
  console.log(`code dig =`, code);  
  console.log(`color dig =`, color);  
  console.log(`image dig =`, image);  

  updateResourceApiRequest(id, title, name, surname, telephone, email, address, code, color, image)
  .then(data => {
      if (data.success === "resource updated") {
          navigate("/admin/resources");
      }
    })
  .catch(er => {
      setUpdateResourceResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data });
  })

  window.location.assign('/admin/resources')
}

function gotoIndietro (eventKey) {
  eventKey.preventDefault();
  eventKey.stopPropagation();

  window.location.assign('/admin/resources')
}


  return (
    <Container fullWidth>

      <Row className="justify-content-center mt-5">

        <Col md={11}>
          <h1>&nbsp;Modifica Risorsa</h1>

          <Form noValidate validated={validated} >

            <Box
              component="form"
              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="off"
            >

              <TextField id="id-cliente" label="Cliente" variant="outlined" value={title} defaultValue={title}
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setTitle(event.target.value);
                }}
              />

              <TextField id="id-name" label="Nome" variant="outlined" value={name} defaultValue={name}
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />

              <TextField id="id-surname" label="Cognome" variant="outlined" value={surname} defaultValue={surname} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setSurname(event.target.value);
                }}
              />

              <TextField id="id-telephone" label="Telefono" variant="outlined" value={telephone} defaultValue={telephone} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setTelephone(event.target.value);
                }}
              />

              <TextField id="id-address" label="Indirizzo" variant="outlined" value={address} defaultValue={address} 
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setAddress(event.target.value);
                }}
              />

              <TextField id="id-code" label="Codice" variant="outlined" value={code} defaultValue={code} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setCode(event.target.value);
                }}
              />

              <TextField id="id-color" label="Colore" variant="outlined" value={color} defaultValue={color} type="color"
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setColor(event.target.value);
                }}
              />

              <TextField id="id-image" label="Immagine" variant="outlined" value={image} defaultValue={image} 
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setImage(event.target.value);
                }}
              />
           
            </Box>

          </Form>

        </Col>

      </Row>

      <Row className="justify-content-center mt-5">
        <Col md={1}>
  
        <Button 
              type="button"
              className="btn btn-warning"
              onClick={(e) => {gotoIndietro(e); }}
              style={{
                width: "80px",
              }}
            >
              Indietro
            </Button>             

         </Col>

          <Col md={1}>
            <Button 
              type="button"
              className="btn btn-primary"
              onClick={(e) => {salvaResourceModificata(e); }}
              style={{
                width: "80px",
              }}
            >
              Salva
            </Button>             

          </Col>
      </Row>

      <Row className="mt-5 justify-content-md-center"></Row>

    </Container>
  );
};

export default EditResourcePageComponent;
