import EditResourcePageComponent from "./components/EditResourcePageComponent";
import axios from "axios";

const fetchResource = async (resourceId) => {
    const { data } = await axios.get(`/api/resources/${resourceId}`);
    return data;
}

const updateResourceApiRequest = async (id, title, name, surname, telephone, email, address, code, color, image) => {
    const { data } = await axios.put(`/api/resources/${id}`, { title, name, surname, telephone, email, address, code, color, image });
    return data;
}

const AdminEditResourcePage = () => {
  
  return <EditResourcePageComponent updateResourceApiRequest={updateResourceApiRequest} fetchResource={fetchResource} />;
};


export default AdminEditResourcePage;
