import {
  Row,
  Col,
  Container,
  Form,
  Button,
} from "react-bootstrap";
import React, { useState, useRef } from "react";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const CreateSitePageComponent = ({
  createSiteApiRequest,
  reduxDispatch,
  newSite,
  deleteSite,
  saveAttributeToCatDoc
}) => {

  const [validated, setValidated] = useState(false);
  const [createProductResponseState, setCreateSiteResponseState] = useState({
    message: "",
    error: "",
  });

  const [name, setName] = useState("");                      
  const [description, setDescription] = useState("");                      
  const [code, setCode] = useState("");                      
  const [address, setAddress] = useState("");                      
  const [image, setImage] = useState("");                      

  function createNewSite (eventKey) {

    eventKey.preventDefault();
    eventKey.stopPropagation();

    console.log(`name dig =`, name);  
    console.log(`description dig =`, description);  
    console.log(`code dig =`, code);  
    console.log(`address dig =`, address);  
    console.log(`image dig =`, image);  

    const formInputs = {
      name: name,
      description: description,
      code: code,
      address:address,
      image: image,
    };

    createSiteApiRequest(formInputs);
    window.location.assign('/admin/sites')
  }

  function gotoIndietro (eventKey) {
    eventKey.preventDefault();
    eventKey.stopPropagation();

    window.location.assign('/admin/sites')
  }

  return (

    <Container fullWidth>
      
      <Row className="justify-content-center mt-5">

        <Col md={11}>
          <h1>&nbsp;Crea nuovo sito</h1>

          <Form noValidate validated={validated} >

            <Box
              component="form"
              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="off"
            >

              <TextField id="id-name" label="Nome" variant="outlined" defaultValue={name}
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />

              <TextField id="id-description" label="Descrizione" variant="outlined" defaultValue={description} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
              />

              <TextField id="id-code" label="Codice" variant="outlined" defaultValue={code} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setCode(event.target.value);
                }}
              />

              <TextField id="id-address" label="Indirizzo" variant="outlined" defaultValue={address} 
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setAddress(event.target.value);
                }}
              />

              <TextField id="id-image" label="Immagine" variant="outlined" defaultValue={image} 
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setImage(event.target.value);
                }}
              />
           
          </Box>

          </Form>

        </Col>

      </Row>

      <Row className="justify-content-center mt-5">
        <Col md={1}>
  
        <Button 
              type="button"
              className="btn btn-warning"
              onClick={(e) => {gotoIndietro(e); }}
              style={{
                width: "80px",
              }}
            >
              Indietro
            </Button>             

         </Col>

          <Col md={1}>
            <Button 
              type="button"
              className="btn btn-primary"
              onClick={(e) => {createNewSite(e); }}
              style={{
                width: "80px",
              }}
            >
              Crea
            </Button>             

            {createProductResponseState.error ?? ""}
          </Col>
      </Row>

      <Row className="mt-5 justify-content-md-center"></Row>

    </Container>

  );
};

export default CreateSitePageComponent;
