import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { InputGroup } from "react-bootstrap";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { red } from "@mui/material/colors";

const EditSitePageComponent = ({ updateSiteApiRequest, fetchSite }) => {
 
  const [validated, setValidated] = useState(false);
  const [site, setSite] = useState([]);
  const [updateSiteResponseState, setUpdateSiteResponseState] = useState({ message: "", error: "" });

  const { id } = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState("");                      
  const [description, setDescription] = useState("");                      
  const [code, setCode] = useState("");                      
  const [address, setAddress] = useState("");                      
  const [image, setImage] = useState("");            
 
  useEffect(() => {
    fetchSite(id)
    .then(data => {
      console.log(`data =`, data);  
      setName(data?.name);
      setDescription(data?.description);
      setCode(data?.code);
      setAddress(data?.address);
      setImage(data?.image);
      console.log(`name  =`, name);  
      console.log(`description  =`, description);  
    })
    .catch((er) => console.log(er.response.data.message ? er.response.data.message : er.response.data));         
  },[id])

  function salvaSiteModificato (eventKey) {
    eventKey.preventDefault();
    eventKey.stopPropagation();

    console.log(`name dig =`, name);  
    console.log(`description dig =`, description);  
    console.log(`code dig =`, code);  
    console.log(`address dig =`, address);  
    console.log(`image dig =`, image);  

    updateSiteApiRequest(id, name, description, code, address, image)
    .then(data => {
        if (data.success === "site updated") {
            navigate("/admin/sites");
        }
      })
    .catch(er => {
        setUpdateSiteResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data });
    })

    window.location.assign('/admin/sites')
  }

  function gotoIndietro (eventKey) {
    eventKey.preventDefault();
    eventKey.stopPropagation();

    window.location.assign('/admin/sites')
  }


  return (
    <Container fullWidth>

    <Row className="justify-content-center mt-5">

      <Col md={11}>
        <h1>&nbsp;Modifica sito</h1>

        <Form noValidate validated={validated} >

          <Box
            component="form"
            sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
            noValidate
            autoComplete="off"
          >

            <TextField id="id-name" label="Nome" variant="outlined" value={name} defaultValue={name}
              style={{
                width: "200px",
              }}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />

            <TextField id="id-description" label="Descrizione" variant="outlined" value={description} defaultValue={description} 
              style={{
                width: "200px",
              }}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            />

            <TextField id="id-code" label="Codice" variant="outlined" value={code} defaultValue={code} 
              style={{
                width: "200px",
              }}
              onChange={(event) => {
                setCode(event.target.value);
              }}
            />

            <TextField id="id-address" label="Mobile" variant="outlined" value={address} defaultValue={address} 
              style={{
                width: "400px",
              }}
              onChange={(event) => {
                setAddress(event.target.value);
              }}
            />

            <TextField id="id-image" label="Immagine" variant="outlined" value={image} defaultValue={image} 
              style={{
                width: "400px",
              }}
              onChange={(event) => {
                setImage(event.target.value);
              }}
            />
         
          </Box>

        </Form>

      </Col>

    </Row>

    <Row className="justify-content-center mt-5">
      <Col md={1}>

      <Button 
            type="button"
            className="btn btn-warning"
            onClick={(e) => {gotoIndietro(e); }}
            style={{
              width: "80px",
            }}
          >
            Indietro
          </Button>             

       </Col>

        <Col md={1}>
          <Button 
            type="button"
            className="btn btn-primary"
            onClick={(e) => {salvaSiteModificato(e); }}
            style={{
              width: "80px",
            }}
          >
            Salva
          </Button>             

        </Col>
    </Row>

    <Row className="mt-5 justify-content-md-center"></Row>

  </Container>

  );
};

export default EditSitePageComponent;
