import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { InputGroup } from "react-bootstrap";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { red } from "@mui/material/colors";

const EditCategoryPageComponent = ({ updateCategoryApiRequest, fetchCategory }) => {

  const [validated, setValidated] = useState(false);
  const [category, setCategory] = useState([]);
  const [updateCategoryResponseState, setUpdateCategoryResponseState] = useState({ message: "", error: "" });

  const { id } = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState("");                      
  const [description, setDescription] = useState("");                      
  const [color, setColor] = useState("");                      
  const [image, setImage] = useState("");                      

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    const form = event.currentTarget.elements;
    const name = form.name.value;
    const description = form.description.value;
    const color = form.color.value;
    const image = form.image.value;

    if (event.currentTarget.checkValidity() === true) {
        updateCategoryApiRequest(id, name, description, color, image)
        .then(data => {
            if (data.success === "category updated") {
                navigate("/admin/categories");
            }
          })
        .catch(er => {
            setUpdateCategoryResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data });
        })
    }

    setValidated(true);
  };

  const [coloreScelto, setColoreScelto] = useState('#ffffff');
  const coloreScelto1 = '#536ef3';

  useEffect(() => {
      fetchCategory(id)
      .then(data => {
          setCategory(data);
      })
      .catch((er) => console.log(er.response.data.message ? er.response.data.message : er.response.data));
      console.log('category = ',category)
  }, [id])

  useEffect(() => {
    fetchCategory(id)
    .then(data => {
      console.log(`data =`, data);  
      setName(data?.name);
      setDescription(data?.description);
      setColor(data?.color);
      setImage(data?.image);
      console.log(`name  =`, name);  
      console.log(`description  =`, description);  
    })
    .catch((er) => console.log(er.response.data.message ? er.response.data.message : er.response.data));         
},[id])


function salvaCategoryModificata (eventKey) {
  eventKey.preventDefault();
  eventKey.stopPropagation();

  console.log(`name dig =`, name);  
  console.log(`description dig =`, description);  
  console.log(`color dig =`, color);  
  console.log(`image dig =`, image);  

  updateCategoryApiRequest(id, name, description, color, image)
  .then(data => {
      if (data.success === "resource updated") {
          navigate("/admin/categories");
      }
    })
  .catch(er => {
      setUpdateCategoryResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data });
  })

  window.location.assign('/admin/categories')
}

function gotoIndietro (eventKey) {
  eventKey.preventDefault();
  eventKey.stopPropagation();

  window.location.assign('/admin/categories')
}


useEffect(() => {
    setColoreScelto(category.color);
    //console.log('color = ',category)
    console.log('coloreScelto = ',coloreScelto)
  }, [])

  return (

    <Container fullWidth>

      <Row className="justify-content-center mt-5">

        <Col md={11}>
          <h1>&nbsp;Modifica categoria</h1>

          <Form noValidate validated={validated} >

            <Box
              component="form"
              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="off"
            >

              <TextField id="id-name" label="Nome" variant="outlined" value={name} defaultValue={name}
                style={{
                  width: "300px",
                }}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />

              <TextField id="id-description" label="Descrizione" variant="outlined" value={description} defaultValue={description} 
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
              />

              <TextField id="id-color" label="Colore" variant="outlined" value={color} defaultValue={color} type="color"
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setColor(event.target.value);
                }}
              />

              <TextField id="id-image" label="Immagine" variant="outlined" value={image} defaultValue={image} 
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setImage(event.target.value);
                }}
              />
           
            </Box>

          </Form>

        </Col>

      </Row>

      <Row className="justify-content-center mt-5">
        <Col md={1}>
  
        <Button 
              type="button"
              className="btn btn-warning"
              onClick={(e) => {gotoIndietro(e); }}
              style={{
                width: "80px",
              }}
            >
              Indietro
            </Button>             

         </Col>

          <Col md={1}>
            <Button 
              type="button"
              className="btn btn-primary"
              onClick={(e) => {salvaCategoryModificata(e); }}
              style={{
                width: "80px",
              }}
            >
              Salva
            </Button>             

          </Col>
      </Row>

      <Row className="mt-5 justify-content-md-center"></Row>

    </Container>


    );
};

export default EditCategoryPageComponent;
