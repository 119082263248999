import {
  Row,
  Col,
  Container,
  Form,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import InputGroup from 'react-bootstrap/InputGroup';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const CreateCategoryPageComponent = ({
  createCategoryApiRequest,
  uploadCategoryImagesApiRequest,
  uploadImagesCloudinaryApiRequest,
  reduxDispatch,
  newCategory,
  deleteCategory,
  saveAttributeToCatDoc
}) => {
  const [validated, setValidated] = useState(false);
  const [attributesTable, setAttributesTable] = useState([]);
  const [attributesFromDb, setAttributesFromDb] = useState([]);
  const [images, setImages] = useState(false);
  const [isCreating, setIsCreating] = useState("");
  const [createProductResponseState, setCreateCategoryResponseState] = useState({
    message: "",
    error: "",
  });


  const [name, setName] = useState("");                      
  const [description, setDescription] = useState("");                      
  const [color, setColor] = useState("");                      
  const [image, setImage] = useState("");                      


  const navigate = useNavigate();

  const handleSubmit = (event) => {

    event.preventDefault();
    event.stopPropagation();
    
    const form = event.currentTarget.elements;
    
    const formInputs = {
      name: form.name.value,
      description: form.description.value,
      color: form.color.value,
      image: form.color.image,
    };
      
      if (images.length > 1) {
        setIsCreating("to many files");
        return;
      }

      createCategoryApiRequest(formInputs)
        .then((data) => {
          if (images) {
            if (process.env.NODE_ENV !== "production") {
              uploadCategoryImagesApiRequest(images, data.categoryId)
                .then((res) => {})
                .catch((er) =>
                  setIsCreating(
                    er.response.data.message
                      ? er.response.data.message
                      : er.response.data
                  )
                );
            } else {
              uploadImagesCloudinaryApiRequest(images, data.categoryId);
            }
          }
          if (data.message === "category created") navigate("/admin/categories");
        })
        .catch((er) => {
          setCreateCategoryResponseState({
            error: er.response.data.message
              ? er.response.data.message
              : er.response.data,
          });
        });
  //  }

    setValidated(true);

    window.location.assign('/admin/categories')
  };

  const uploadHandler = (images) => {
    setImages(images);
  };



 function createNewCategory (eventKey) {

    eventKey.preventDefault();
    eventKey.stopPropagation();

    console.log(`name dig =`, name);  
    console.log(`description dig =`, description);  
    console.log(`color dig =`, color);  
    console.log(`image dig =`, image);  

    const formInputs = {
      name: name,
      description: description,
      color: color,
      image: image,
    };

    createCategoryApiRequest(formInputs);
    window.location.assign('/admin/categories')
  }

  function gotoIndietro (eventKey) {
    eventKey.preventDefault();
    eventKey.stopPropagation();

    window.location.assign('/admin/categories')
  }

  return (

    <Container fullWidth>
      
    <Row className="justify-content-center mt-5">

      <Col md={11}>
        <h1>&nbsp;Crea nuova categoria</h1>

        <Form noValidate validated={validated} >

          <Box
            component="form"
            sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
            noValidate
            autoComplete="off"
          >

            <TextField id="id-name" label="Nome" variant="outlined" defaultValue={name}
              style={{
                width: "300px",
              }}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />

            <TextField id="id-description" label="Descrizione" variant="outlined" defaultValue={description} 
              style={{
                width: "400px",
              }}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            />

            <TextField id="id-color" label="Colore" variant="outlined" defaultValue={color} type="color"
              style={{
                width: "200px",
              }}
              onChange={(event) => {
                setColor(event.target.value);
              }}
            />

            <TextField id="id-image" label="Immagine" variant="outlined" defaultValue={image} 
              style={{
                width: "400px",
              }}
              onChange={(event) => {
                setImage(event.target.value);
              }}
            />
         
        </Box>

        </Form>

      </Col>

    </Row>

    <Row className="justify-content-center mt-5">
      <Col md={1}>

      <Button 
            type="button"
            className="btn btn-warning"
            onClick={(e) => {gotoIndietro(e); }}
            style={{
              width: "80px",
            }}
          >
            Indietro
          </Button>             

       </Col>

        <Col md={1}>
          <Button 
            type="button"
            className="btn btn-primary"
            onClick={(e) => {createNewCategory(e); }}
            style={{
              width: "80px",
            }}
          >
            Crea
          </Button>             

          {createProductResponseState.error ?? ""}
        </Col>
    </Row>

    <Row className="mt-5 justify-content-md-center"></Row>

  </Container>  


  );
};

export default CreateCategoryPageComponent;
