import {
  Row,
  Col,
  Container,
  Form,
  Button,
} from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const CreateCustomerPageComponent = ({
  createCustomerApiRequest,
  reduxDispatch,
  newCustomer,
  deleteCustomer,
  saveAttributeToCatDoc
}) => {

  const [validated, setValidated] = useState(false);
  const [createProductResponseState, setCreateCustomerResponseState] = useState({
    message: "",
    error: "",
  });

  const [title, setTitle] = useState("");                      
  const [name, setName] = useState("");                      
  const [surname, setSurname] = useState("");                      
  const [telephone, setTelephone] = useState("");                      
  const [mobile, setMobile] = useState("");                      
  const [email, setEmail] = useState("");                      
  const [via, setVia] = useState("");                      
  const [cap, setCap] = useState("");                      
  const [citta, setCitta] = useState("");                      
  const [provincia, setProvincia] = useState("");                      
  const [piva, setPiva] = useState("");                      
  const [codicefiscale, setCodicefiscale] = useState("");                      

  function createNewCustomer (eventKey) {

    eventKey.preventDefault();
    eventKey.stopPropagation();

    console.log(`title dig =`, title);  
    console.log(`name dig =`, name);  
    console.log(`surname dig =`, surname);  
    console.log(`telephone dig =`, telephone);  
    console.log(`mobile dig =`, mobile);  
    console.log(`email dig =`, email);  
    console.log(`via dig =`, via);  
    console.log(`cap dig =`, cap);  
    console.log(`citta dig =`, citta);  
    console.log(`provincia dig =`, provincia);  
    console.log(`piva dig =`, piva);  
    console.log(`codicefiscale dig =`, codicefiscale);  

    const formInputs = {
      title: title,
      name: name,
      surname: surname,
      telephone: telephone,
      mobile: mobile,
      email: email,
      via: via,
      cap: cap,
      citta: citta,
      provincia: provincia,
      piva: piva,
      codicefiscale: codicefiscale,
      image: "",
    };

    createCustomerApiRequest(formInputs);
    window.location.assign('/admin/customers')
  }

  function gotoIndietro (eventKey) {
    eventKey.preventDefault();
    eventKey.stopPropagation();

    window.location.assign('/admin/customers')
  }

  return (

    <Container fullWidth>
      
      <Row className="justify-content-center mt-5">

        <Col md={11}>
          <h1>&nbsp;Crea nuovo cliente</h1>

          <Form noValidate validated={validated} >

            <Box
              component="form"
              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="off"
            >

              <TextField id="id-title" label="Cliente" variant="outlined" defaultValue={title}
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setTitle(event.target.value);
                }}
              />

              <TextField id="id-name" label="Nome" variant="outlined" defaultValue={name}
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />

              <TextField id="id-surname" label="Cognome" variant="outlined" defaultValue={surname} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setSurname(event.target.value);
                }}
              />

              <TextField id="id-telephone" label="Telefono" variant="outlined" defaultValue={telephone} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setTelephone(event.target.value);
                }}
              />

              <TextField id="id-mobile" label="Mobile" variant="outlined" defaultValue={mobile} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setMobile(event.target.value);
                }}
              />

              <TextField id="id-email" label="Email" variant="outlined" defaultValue={email} 
                style={{
                  width: "300px",
                }}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />

              <TextField id="id-via" label="Via" variant="outlined" defaultValue={via} 
                style={{
                  width: "300px",
                }}
                onChange={(event) => {
                  setVia(event.target.value);
                }}
              />

              <TextField id="id-cap" label="CAP" variant="outlined" defaultValue={cap} 
                style={{
                  width: "100px",
                }}
                onChange={(event) => {
                  setCap(event.target.value);
                }}
              />

              <TextField id="id-citta" label="Città" variant="outlined" defaultValue={citta} 
                style={{
                  width: "300px",
                }}
                onChange={(event) => {
                  setCitta(event.target.value);
                }}
              />

              <TextField id="id-provincia" label="Provincia" variant="outlined" defaultValue={provincia} 
                style={{
                  width: "100px",
                }}
                onChange={(event) => {
                  setProvincia(event.target.value);
                }}
              />

              <TextField id="id-piva" label="Partita iva" variant="outlined" defaultValue={piva} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setPiva(event.target.value);
                }}
              />

              <TextField id="id-codicefiscale" label="Codice fiscale" variant="outlined" defaultValue={codicefiscale} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setCodicefiscale(event.target.value);
                }}
              />
           
          </Box>

          </Form>

        </Col>

      </Row>

      <Row className="justify-content-center mt-5">
        <Col md={1}>
  
        <Button 
              type="button"
              className="btn btn-warning"
              onClick={(e) => {gotoIndietro(e); }}
              style={{
                width: "80px",
              }}
            >
              Indietro
            </Button>             

         </Col>

          <Col md={1}>
            <Button 
              type="button"
              className="btn btn-primary"
              onClick={(e) => {createNewCustomer(e); }}
              style={{
                width: "80px",
              }}
            >
              Crea
            </Button>             

            {createProductResponseState.error ?? ""}
          </Col>
      </Row>

      <Row className="mt-5 justify-content-md-center"></Row>

    </Container>
  );
};

export default CreateCustomerPageComponent;
